/* You can add global styles to this file, and also import other style files */
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

html { height: 100%; }
body {
    margin: 0; 
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
    font-family:'Open Sans', sans-serif;
    font-weight: 500;
    line-height:40px;
}
h1 {
    color:#11B674;
    font-weight: 700;
}
.text-center {
    text-align: center; 
}

.main-top-margin {
     margin-top: 75px;
}
.main-low-margin {
     margin-top: 55px;
}

a,a:hover {
    text-decoration:none;
}

p {
    line-height: 30px;
    margin-bottom: 23px;
}

.img-margin-product {
    margin-top:30px;
}
.bottam-pad {
     padding-bottom:40px;
}
.text-justify {
    text-align:justify;
}

.color-red {
    color:#ff0000;
}

.color-green {
    color:#07BB00;
}

.color-light-blue {
    color:#BB00BB;
}

.color-blue {
    color:#0052FF;
}
.color-brown {
    color:#EB8F2D;
}
.color-black {
    color:#000000;
}

.color-linkedin {
    color:#007bb6;
}